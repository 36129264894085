import React from 'react';
import { Form, Input, Button,Select,message,Spin } from 'antd';
import { Table,Collapse,Popover,Popconfirm,Descriptions,Modal } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import * as serverconfig from '../../views/serverconn'
import moment from 'moment';

import { SearchOutlined,LoadingOutlined,DeleteOutlined,FundViewOutlined,WalletFilled,EditOutlined } from '@ant-design/icons';

const dateFormat = 'HHmmssDDMMYYYY';

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''


class MobileClientCreateForm extends React.Component {
  state = {
    clientname:'',  
    companyemail:'',
    companylogo:null,   
    companyphone:'',
    boxnumber:'',
    companyphonetwo:'',
    companyphonethree:'',
    datarequested:true,  
    package:'',
    update_url:'',
    apicode:'',
    client_type:'',
    collection_charge:0,
    collection_chargetype:'',
    payout_charge:0,
    payout_chargetype:''

  }


  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

  axios.defaults.headers={
    "Content-Type":"application/json",
    Authorization:`Token ${token}`
  }

    this.setState({datarequested:false});

}


  //submit button pressed
  handleFormSubmit=(event) =>{
    
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('client_name', this.state.clientname);
    form_data.append('company_email', this.state.companyemail);
    form_data.append('RegisteredPhoneNumber1', this.state.companyphone);
    form_data.append('box_number', this.state.boxnumber);

    this.state.companylogo==null?
    console.log("No profile file")
    :
    form_data.append('client_logo', this.state.companylogo,this.state.companylogo?this.state.companylogo.name:"");
    
    form_data.append('RegisteredPhoneNumber2', this.state.companyphonetwo);
    form_data.append('RegisteredPhoneNumber3', this.state.companyphonethree);

    form_data.append('apicode', this.state.apicode);
    form_data.append('is_active', false);
    form_data.append('update_url', this.state.update_url);
    form_data.append('collection_charge', this.state.collection_charge);
    form_data.append('collection_chargetype', this.state.collection_chargetype);

    form_data.append('payout_charge', this.state.payout_charge);
    form_data.append('payout_chargetype', this.state.payout_chargetype);

    form_data.append('client_type', this.state.client_type);

      axios.post(serverconfig.backendserverurl+'/api/mobilemoneyclients/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>{
      console.log(res.data)
      this.setState({datarequested:false})
      window.location.reload(false)
      message.info('Successfully created client')
      
    } )
    .catch(error => console.log(error))

  }

  handleclientnameChange= (e) => this.setState({ clientname: e.target.value});
  handleCompanyEmailChange= (e) => this.setState({ companyemail: e.target.value});
  handleCompanyLogoChange= (e) => this.setState({ companylogo: e.target.files[0]});

  handleCompanyPhoneChange= (e) => this.setState({ companyphone: e});
  handleCompanyBoxNumberChange= (e) => this.setState({ boxnumber: e.target.value});

  handleCompanyPhonetwoChange= (e) => this.setState({ companyphonetwo: e});
  handleCompanyPhonethreeChange= (e) => this.setState({ companyphonethree: e});

  handleupdate_urlChange= (e) => this.setState({ update_url: e.target.value});
  handleapicodeChange= (e) => this.setState({ apicode: e.target.value});
  handlecollection_chargeChange= (e) => this.setState({ collection_charge: e.target.value});

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div >
          <Form
          onFinish={(event) => this.handleFormSubmit(event)}
          >
            <FormItem label="Client type"
            name="mobileclient"
            rules={[
              {
                required: true,
                message: 'Please Select client type',
              },
            ]}
             >
                <Select 
                placeholder="Client type" 
                //style={{ width: 120 }} 
                value={this.state.client_type} 
                onChange={(val)=>{this.setState({client_type:val})}}
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                >
                  <Option value={"banking"}>Banking</Option>
                  <Option value={"other"}>Other</Option>

                </Select>
            </FormItem>



            <FormItem label="Client Name"
                name="clientname"
                rules={[
                  {
                    required: true,
                    message: 'Please input name',
                  },
                ]}
              >
              <Input name="clientname"  placeholder="Put a name here." value={this.state.clientname} onChange={this.handleclientnameChange} />
            </FormItem>
  
            <FormItem label="Client Logo">
              <Input name="companylogo" type="file" accept="image/png, image/jpeg" placeholder="Company logo"  onChange={this.handleCompanyLogoChange}/>
            </FormItem>  
            
            <FormItem label="Client Email"
                name="companyemail"
                rules={[
                  {
                    required: true,
                    message: 'Please input email',
                  },
                ]}
            >
              <Input name="companyemail" type="email"  placeholder="company@email.com" value={this.state.companyemail} onChange={this.handleCompanyEmailChange} />
            </FormItem> 
  
            <FormItem label="Phone Number">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphone} onChange={this.handleCompanyPhoneChange}/>
             </FormItem>
             <FormItem label="Phone Number Two">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphonetwo} onChange={this.handleCompanyPhonetwoChange}/>
             </FormItem> 
  
             <FormItem label="Phone Number Three">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphonethree} onChange={this.handleCompanyPhonethreeChange}/>
             </FormItem>  

            <FormItem label="Box number">
              <Input name="boxnumber"  placeholder="Box number here" value={this.state.boxnumber} onChange={this.handleCompanyBoxNumberChange} />
            </FormItem>
  
            <FormItem label="Transaction Update Url"
                name="update_url"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Url',
                  },
                ]}              
            >
              <Input name="update_url"  placeholder="Update Url here" value={this.state.update_url} onChange={this.handleupdate_urlChange} />
            </FormItem>

            <FormItem label="Api Code"
                name="apicode"
                rules={[
                  {
                    required: true,
                    message: 'Please enter code',
                  },
                ]}            
            >
              <Input maxLength={6} name="apicode"  placeholder="Api Code" value={this.state.apicode} onChange={this.handleapicodeChange} />
            </FormItem>



            <FormItem label="Collection charge type"
            name="collection_chargetype"
            rules={[
              {
                required: true,
                message: 'Please Select charge type',
              },
            ]}
             >
                <Select 
                placeholder="Charge type" 
                //style={{ width: 120 }} 
                value={this.state.collection_chargetype} 
                onChange={(val)=>{this.setState({collection_chargetype:val})}}
                showSearch
                optionFilterProp="children"
                >
                  <Option value={"byblockfigure"}>By block figure</Option>
                  <Option value={"bypercentage"}>By percentage</Option>

                </Select>
            </FormItem>


            <FormItem label="Collection charge"
                name="collection_charge"
                rules={[
                  {
                    required: true,
                    message: 'Please enter value',
                  },
                ]}            
            >
              <Input maxLength={6} name="collection_charge" type='number'  placeholder="Retained Percentage  eg 3" value={this.state.collection_charge} onChange={this.handlecollection_chargeChange} />
            </FormItem>


            <FormItem label="Payout charge type"
            name="payout_chargetype"
            rules={[
              {
                required: true,
                message: 'Please Select charge type',
              },
            ]}
             >
                <Select 
                placeholder="Payout type" 
                //style={{ width: 120 }} 
                value={this.state.payout_chargetype} 
                onChange={(val)=>{this.setState({payout_chargetype:val})}}
                showSearch
                optionFilterProp="children"
                >
                  <Option value={"byblockfigure"}>By block figure</Option>
                  <Option value={"bypercentage"}>By percentage</Option>

                </Select>
            </FormItem>


            <FormItem label="Payout charge"
                name="payout_charge"
                rules={[
                  {
                    required: true,
                    message: 'Please enter value',
                  },
                ]}            
            >
              <Input maxLength={6} name="payout_charge" type='number'  placeholder="Value" value={this.state.payout_charge} onChange={(val)=>{this.setState({payout_charge:val.target.value})}} />
            </FormItem>

            <FormItem>
              <Button type="primary" htmlType="submit" >Create</Button>
            </FormItem>
          </Form>
        </div>
      );
    }

  }

}


export default MobileClientCreateForm;



