import React from 'react';
import { Form, Input, Button,Select,message,Spin } from 'antd';
import { Table,Collapse,Popover,Popconfirm,Descriptions,Modal } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import * as serverconfig from '../../views/serverconn'
import moment from 'moment';

import { SearchOutlined,LoadingOutlined,DeleteOutlined,FundViewOutlined,WalletFilled,EditOutlined } from '@ant-design/icons';

const dateFormat = 'HHmmssDDMMYYYY';

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''


class DashWithdrawForm extends React.Component {

  state = {
    reason:'',  
    licence:'',
    remark:'',
    companylogo:null,   
    companyphone:'',
    amount:'',
    accountslimit:0,
    branchname:'',
    website:'',
    address:'',
    datarequested:true,  
    safebalance:0,
    sharevalue:0,
    retainedearnings:0,
    packages:[],
    package:''
  }


  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({datarequested:false});


}


  //submit button pressed
  handleFormSubmit=(event) =>{

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('client_name', this.state.reason);
    form_data.append('company_email', this.state.remark);
    form_data.append('RegisteredPhoneNumber1', this.state.companyphone);
    form_data.append('box_number', this.state.amount);

    this.state.companylogo==null?
    console.log("No profile file")
    :
    form_data.append('client_logo', this.state.companylogo,this.state.companylogo?this.state.companylogo.name:"");
    

    form_data.append('apicode', Math.floor(100000 + Math.random() * 900000));


      axios.post(serverconfig.backendserverurl+'/api/mobilemoneyclients/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res => this.setState({datarequested:false})
    , window.location.reload(false),message.info('Successfully created client')
    )
    .catch(error => console.log(error))

  }

  handlereasonChange= (e) => this.setState({ reason: e.target.value});
  handleremarkChange= (e) => this.setState({ remark: e.target.value});
  handleCompanyPhoneChange= (e) => this.setState({ companyphone: e});
  handleCompanyamountChange= (e) => this.setState({ amount: e.target.value});


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div >
          <Form
          onFinish={(event) => this.handleFormSubmit(event)}
          >

            <FormItem label="Phone Number">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphone} onChange={this.handleCompanyPhoneChange}/>
             </FormItem>

             
            <FormItem label="Amount">
              <Input name="amount" type='number'  placeholder="Amount here" value={this.state.amount} onChange={this.handleCompanyamountChange} />
            </FormItem>


            <FormItem label="Reason"
                name="reason"
                rules={[
                  {
                    required: true,
                    message: 'Please input reason',
                  },
                ]}
              >
              <Input name="reason"  placeholder="Put reason here." value={this.state.reason} onChange={this.handlereasonChange} />
            </FormItem>
  

            <FormItem label="Remark"
                name="remark"
                rules={[
                  {
                    required: true,
                    message: 'Please input remark',
                  },
                ]}
            >
              <Input name="remark" type="email"  placeholder="Put remark here" value={this.state.remark} onChange={this.handleremarkChange} />
            </FormItem> 


            <FormItem>
              <Button type="primary" htmlType="submit" >Submit</Button>
            </FormItem>
          </Form>
        </div>
      );
    }


   
  }

}


export default DashWithdrawForm;



