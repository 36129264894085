import React,{useState,useEffect,lazy} from 'react';
import axios from 'axios'
import {  Slider,Divider,Statistic,Collapse,Avatar,Tooltip,Button } from 'antd';
import { ArrowUpOutlined, SearchOutlined,ArrowDownOutlined,CreditCardFilled,MessageFilled,LoadingOutlined,
   UserOutlined, AccountBookOutlined,SlidersFilled,MinusCircleFilled,BankFilled,DropboxOutlined,
   DollarCircleOutlined,GroupOutlined,PlusCircleFilled,FileFilled,DollarCircleFilled,MinusSquareFilled,FundFilled,PlusSquareFilled } from '@ant-design/icons';
import moment from 'moment';
import { Line,Pie,Bar } from 'react-chartjs-2';
import {withGetScreen} from 'react-getscreen'
import {
  Col,
  Row,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import * as serverconfig from '../../views/serverconn'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';
import { NavLink } from 'react-router-dom'
import { Table, Input,Popover,Modal,Tabs
  ,Card,Form,Select,InputNumber,Drawer,Descriptions,Popconfirm,message,Spin } from 'antd';
import MobileClientCreateForm from '../components/mobileclientcreateform'
import DashCollectionForm from '../components/dashcollectionform'
import DashWithdrawForm from '../components/dashwithdrawform'
import Highlighter from 'react-highlight-words';

const style = { background: '#0092ff', padding: '8px 0',borderRadius: '7px',justifyContent:'center', alignItems:'center',display: 'flex', };
const dateFormat = 'DD/MM/YYYY';
const yeardateFormat = 'YYYY';
const monthdateFormat = 'MM/YYYY';

const { Panel } = Collapse;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;

var token= ''

class AdminIndex extends React.Component {

  state = {
    expenses:[],
    searchText: '',
    searchedColumn: '',
    date:moment().format(dateFormat).toString(),
    yearlymonths:[],
    yearlymonthlysales:[],
    totalnoofclients:0,
    monthlyinvoicetotalamount:0,
    showprintermodal:false,
    invoicesbycompany:[],
    companies:[],
    invoicetotals:[],
    licencedcompanies:[],
    companylicences:[],
    companyprofile:{},
    datarequested:true,
    showpackagecreatemodal:false,
    showwithdrawmodal:false,
    showcollectionmodal:false,
    pendingtransactions:[],
    successfulltransactions:[],
    failedtransactions:[],
  };

  callback =(key) =>{
    console.log(key);
    console.log(this.state.yearlymonthlysales)
  }

  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    console.log("token: "+token)

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/mobilemoneyclients/`)
    .then(res => {
        this.setState({
          totalnoofclients:res.data.length
        })
    })



        ///////////////////////////////////////////////////////////////////////////////
    /// getting the expense amounts by category
    let form_data2 = new FormData();
    form_data2.append('month',moment().format(monthdateFormat).toString());
 
      //Now submit sale data to database
      axios.get(serverconfig.backendserverurl+'/customqueries/getlicencesbycompany',form_data2,{
        headers: {
          'content-type': 'multipart/form-data'
        }
          })
          .then(res =>{
              const companys = [];
              const companylicences = [];

              JSON.parse(res.data.licencereport).map(
                (item)=>{
                  companys.push(item.company_name)
                  companylicences.push(Number(item.licencedays))
                })

                this.setState({
                  licencedcompanies:companys})

                this.setState({
                  companylicences:companylicences}) 
            } 
          )
          .catch(error => console.log(error)) 

    //get the company profile here
    axios.get(`${serverconfig.backendserverurl}/api/companyprofile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
        this.setState({datarequested:false})
    })


    axios.get(serverconfig.backendserverurl+`/api/mobilemoneytransactions/?status=${'PENDING'}`)
    .then(res => {
        this.setState({
          pendingtransactions:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/mobilemoneytransactions/?status=${'SUCCESS'}`)
    .then(res => {
        this.setState({
          successfulltransactions:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/mobilemoneytransactions/?status=${'FAILED'}`)
    .then(res => {
        this.setState({
          failedtransactions:res.data
        })
    })

}

//display sacco create modal
displaypaymentmodal=()=>{
  this.setState({showprintermodal:true})

}

closeprintermodal=()=>{
  this.setState({showprintermodal:false})
}


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ?(
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});


render() {
  var pendingcolumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      ...this.getColumnSearchProps('date'),
    },
    {
      title: 'Client',
      dataIndex: 'mobileclientname',
      key: 'id',
      ...this.getColumnSearchProps('mobileclientname'),
    },
    {
      title: 'Tel',
      dataIndex: 'Phone',
      key: 'id',
    },
      
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'id',
      render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
    },
    {
      title: 'Transaction Id',
      dataIndex: 'transactionID',
      key: 'id',
      ...this.getColumnSearchProps('transactionID'),
    },
    
    {
      title: 'Transaction Type',
      dataIndex: 'transactiontype',
      key: 'id',
      ...this.getColumnSearchProps('transactiontype'),
    }, 

  ];  


  var successfullcolumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      ...this.getColumnSearchProps('date'),
    },
    {
      title: 'Client',
      dataIndex: 'mobileclientname',
      key: 'id',
      ...this.getColumnSearchProps('mobileclientname'),
    },
    {
      title: 'Tel',
      dataIndex: 'Phone',
      key: 'id',
    },
      
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'id',
      render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
    },
    {
      title: 'Transaction Id',
      dataIndex: 'transactionID',
      key: 'id',
      ...this.getColumnSearchProps('transactionID'),
    },
    
    {
      title: 'Transaction Type',
      dataIndex: 'transactiontype',
      key: 'id',
      ...this.getColumnSearchProps('transactiontype'),
    }, 

  
  ]; 



  var failedcolumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      ...this.getColumnSearchProps('date'),
    },
    {
      title: 'Client',
      dataIndex: 'mobileclientname',
      key: 'id',
      ...this.getColumnSearchProps('mobileclientname'),
    },
    {
      title: 'Tel',
      dataIndex: 'Phone',
      key: 'id',
    },
      
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'id',
      render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
    },
    {
      title: 'Transaction Id',
      dataIndex: 'transactionID',
      key: 'id',
      ...this.getColumnSearchProps('transactionID'),
    },
    
    {
      title: 'Transaction Type',
      dataIndex: 'transactiontype',
      key: 'id',
      ...this.getColumnSearchProps('transactiontype'),
    }, 

  
  ]; 

  if(this.state.datarequested===true){
    return(
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
      <Spin indicator={antIcon} />
      </div>
    )

  }else{

    return(
    
      <div className="animated fadeIn">
      <Row>
      <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
       <Card
          //onClick={()=>{}}
          hoverable
          style={{ 
            background:"white",
            display: 'flex',
            justifyContent:'center',
            padding:4,
            alignItems:'center' }}
          cover={<GroupOutlined  style={{color:'#154360',fontSize: '50px'}} />} 
        >
          <Meta title={<h3 style={{color:"#154360"}}><Button type='primary' onClick={() => this.displaypaymentmodal()}>Add Mobile Client</Button></h3>} description={<h5><NavLink to='/mobileclients/'>{this.state.totalnoofclients} CLIENTS </NavLink></h5>} />
        </Card>
       </Col>

       <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
       <Card
          onClick={() => {this.setState({showcollectionmodal:true})}}
          hoverable
          style={{ 
            background:"white",
            display: 'flex',
            justifyContent:'center',
            padding:4,
            alignItems:'center' }}
          cover={<PlusCircleFilled  style={{color:'#154360',fontSize: '50px'}} />} 
        >
          <Meta title={<h3 style={{color:"#154360"}}>COLLECT</h3>} description={<h6>Click to collect from user</h6>} />
        </Card>
       </Col>

       </Row>

       <Tabs  type="card">
          <TabPane tab="Pending Transactions" key="1" defaultActiveKey="1">
              <Collapse onChange={this.callback} defaultActiveKey={['1']}>
                <Panel header="Pending Transactions" key="1">
                <Table columns={pendingcolumns} 
                pagination={{showQuickJumper:true,showSizeChanger:true }}
                dataSource={this.state.pendingtransactions}
                scroll={{ x: 1000 }}
                bordered/>
                </Panel>
              </Collapse>            
          </TabPane>

          <TabPane tab="Successfull Transactions" key="2" >
              <Collapse defaultActiveKey={['1']} onChange={this.callback}>
                <Panel header="Successfull Transactions" key="1">
                <Table columns={successfullcolumns} 
                pagination={{showQuickJumper:true,showSizeChanger:true }}
                dataSource={this.state.successfulltransactions}
                scroll={{ x: 1000 }}
                bordered/>
                </Panel>
              </Collapse>
          </TabPane>

          <TabPane tab="Failed Transactions" key="3" >
              <Collapse defaultActiveKey={['1']} onChange={this.callback}>
                <Panel header="Failed Transactions" key="1">
                <Table columns={failedcolumns} 
                pagination={{showQuickJumper:true,showSizeChanger:true }}
                dataSource={this.state.failedtransactions}
                scroll={{ x: 1000 }}
                bordered/>
                </Panel>
              </Collapse>         
          </TabPane>
            </Tabs>

       
      
       <Modal              
          visible={this.state.showprintermodal}
          title="Mobile Client Registration"
          width={600}
          onCancel={this.closeprintermodal}
      
          footer={[
            <Button key="back" onClick={this.closeprintermodal}>
              Cancel
            </Button>
            ]}
            >
              <MobileClientCreateForm />
                                  
      </Modal>


      <Modal              
          visible={this.state.showcollectionmodal}
          title="Mobile Money Collection Form"
          width={600}
          onCancel={()=>{this.setState({showcollectionmodal:false})}}
      
          footer={[
            <Button key="back" onClick={()=>{this.setState({showcollectionmodal:false})}}>
              Cancel
            </Button>
            ]}
            >
              <DashCollectionForm />
                                  
      </Modal>


      <Modal              
          visible={this.state.showwithdrawmodal}
          title="Mobile Money Withdraw"
          width={600}
          onCancel={()=>{this.setState({showwithdrawmodal:false})}}
      
          footer={[
            <Button key="back" onClick={()=>{this.setState({showwithdrawmodal:false})}}>
              Cancel
            </Button>
            ]}
            >
              <DashWithdrawForm />                  
      </Modal>

      </div>
      )
  }
  
  }
}

export default AdminIndex; 
