import React from 'react';
import { Form, Input, Button,Select,message,Spin } from 'antd';
import { Table,Collapse,Popover,Popconfirm,Descriptions,Modal } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import * as serverconfig from '../../views/serverconn'
import moment from 'moment';

import { SearchOutlined,LoadingOutlined,DeleteOutlined,FundViewOutlined,WalletFilled,EditOutlined } from '@ant-design/icons';

const dateFormattn = 'HHmmssDDMMYYYY';
const dateFormat = 'DD/MM/YYYY';

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''


class DashCollectionForm extends React.Component {

  state = {
    reason:'',  
    licence:'',
    remark:'',
    companylogo:null,   
    companyphone:'',
    amount:0,
    accountslimit:0,
    branchname:'',
    website:'',
    address:'',
    datarequested:true,  
    safebalance:0,
    sharevalue:0,
    retainedearnings:0,
    packages:[],
    package:'',
    clientid:'',
    clients:[]
  }


  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+'/api/mobilemoneyclients/')
    .then(res => {
        this.setState({
          clients:res.data
        })

        this.setState({datarequested:false})
    })

}

  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }



  //submit button pressed
  handleFormSubmit=(event) =>{
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    if(this.state.amount===0){
      alert("amount cant be zero")
    }else if(this.state.companyphone===''){
      alert("Phone number cant be empty")
    }

    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('reason', this.state.reason);
    form_data.append('remark', this.state.remark);
    form_data.append('phone', this.state.companyphone);
    form_data.append('amount', this.state.amount);
    form_data.append('requestfrom', "dashboard");
    form_data.append('transactionId', "TXN"+moment().format(dateFormattn).toString());
    form_data.append('date', moment().format(dateFormat).toString());
    form_data.append('clientid', this.state.clientid);

    axios.post(serverconfig.backendserverurl+'/customqueries/makemobilecollection', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>{
    this.setState({datarequested:false})
    this.setState({reason:''})
    this.setState({remark:''})
    this.setState({companyphone:''})
    this.setState({amount:0})

    if(res.data.Status==="OK"){
      message.info("Successful transaction")
    }else{
      message.info("Failed transaction")
    }

   } 
  )
  .catch(error => console.log(error))

  }

  handlereasonChange= (e) => this.setState({ reason: e.target.value});
  handleremarkChange= (e) => this.setState({ remark: e.target.value});
  handleCompanyPhoneChange= (e) => this.setState({ companyphone: e});
  handleCompanyamountChange= (e) => this.setState({ amount: e.target.value});
  handleclientChange= (e) => this.setState({ clientid: e});

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{
      return (
        <div >
          <Form
          onFinish={(event) => this.handleFormSubmit(event)}
          >

          <FormItem label="Mobile Client"
            name="mobileclient"
            rules={[
              {
                required: true,
                message: 'Please Select Client',
              },
            ]}
             >
                <Select 
                placeholder="Mobile Client" 
                //style={{ width: 120 }} 
                value={this.state.clientid} 
                onChange={this.handleclientChange}
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                >
                  {this.state.clients.map(
                    (clnt)=>(
                      <Option value={clnt.id}>{clnt.client_name}</Option>
                    ))}
                </Select>
            </FormItem>

            <FormItem label="Phone Number">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphone} onChange={this.handleCompanyPhoneChange}/>
             </FormItem>

             
            <FormItem label="Amount">
              <Input name="amount" type='number'  placeholder="Amount here" value={this.state.amount} onChange={this.handleCompanyamountChange} />
            </FormItem>

            <FormItem label="Reason"
                name="reason"
                rules={[
                  {
                    required: true,
                    message: 'Please input reason',
                  },
                ]}
              >
              <Input name="reason"  placeholder="Put reason here." value={this.state.reason} onChange={this.handlereasonChange} />
            </FormItem>
  
            <FormItem label="Remark"
                name="remark"
                rules={[
                  {
                    required: true,
                    message: 'Please input remark',
                  },
                ]}
            >
              <Input name="remark" placeholder="Put remark here" value={this.state.remark} onChange={this.handleremarkChange} />
            </FormItem> 

            <FormItem>
              <Button type="primary" htmlType="submit" >Submit</Button>
            </FormItem>
          </Form>
        </div>
      );
    }

  }

}


export default DashCollectionForm;



