import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Avatar,Spin,Image,Modal,Input,message,Select,Checkbox,Switch } from 'antd';
import CompanyProfileForm from '../components/CompanyProfileForm'
import { UserOutlined } from '@ant-design/icons';
import { PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import ImgsViewer from 'react-images-viewer'
import PhoneInput from 'react-phone-number-input'
import * as serverconfig from '../../views/serverconn'
import moment from 'moment';

import {
    Col,
    Row,
  } from 'reactstrap';

const FormItem=Form.Item;
const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'HHmmssDDMMYYYY';

var token= ''


class MobileClientDetail extends React.Component{

    state={
        profile:{},
        clientname:'',  
        client_logo:'',
        companyemail:'',
        companylogo:null,   
        companyphone:'',
        boxnumber:'',
        companyphonetwo:'',
        companyphonethree:'',
        datarequested:true,  
        package:'',
        update_url:'',
        apicode:'',
        floatBalance:0,
        activated:false,

        sharesfeature:false,
        userbalancesfeature:false,
        retainedpercentage:0,
        client_type:'',

        collection_charge:0,
        collection_chargetype:'',
        payout_charge:0,
        payout_chargetype:''


    }


    componentDidMount(){
        if(localStorage.getItem("token")){
            token= localStorage.getItem("token")
         }else{
            token= ''
         }

        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }

        //component mount taks
        this.componentmounttasks();

    }

    //component mount tasks
    componentmounttasks=()=>{
        const clientId=this.props.match.params.clientId;
        axios.get(`${serverconfig.backendserverurl}/api/mobilemoneyclients/${clientId}`)
        .then(res => {  
            this.setState({
                profile: res.data
            })

            this.setState({clientname:res.data.client_name})
            this.setState({client_logo:res.data.client_logo})
            this.setState({companyemail:res.data.company_email})
            this.setState({companyphone:res.data.RegisteredPhoneNumber1})
            this.setState({companyphonetwo:res.data.RegisteredPhoneNumber2})
            this.setState({companyphonethree:res.data.RegisteredPhoneNumber3})
            this.setState({boxnumber:res.data.box_number})
            this.setState({apicode:res.data.apicode})
            this.setState({floatBalance:res.data.floatBalance})
            this.setState({activated:res.data.activated})
            this.setState({update_url:res.data.update_url})
            this.setState({retainedpercentage:res.data.retainedpercentage})
            this.setState({client_type:res.data.client_type})

            this.setState({collection_charge:res.data.collection_charge})
            this.setState({collection_chargetype:res.data.collection_chargetype})
            this.setState({payout_charge:res.data.payout_charge})
            this.setState({payout_chargetype:res.data.payout_chargetype})



            this.setState({datarequested:false})

        })


 
    }

    handleclientnameChange= (e) => this.setState({ clientname: e.target.value});
    handleCompanyEmailChange= (e) => this.setState({ companyemail: e.target.value});
    handleCompanyLogoChange= (e) => this.setState({ companylogo: e.target.files[0]});
    handleCompanyPhoneChange= (e) => this.setState({ companyphone: e});
    handleCompanyBoxNumberChange= (e) => this.setState({ boxnumber: e.target.value});
    handleCompanyPhonetwoChange= (e) => this.setState({ companyphonetwo: e});
    handleCompanyPhonethreeChange= (e) => this.setState({ companyphonethree: e});
    handleupdate_urlChange= (e) => this.setState({ update_url: e.target.value});
    handleretainedpercentageChange= (e) => this.setState({ retainedpercentage: e.target.value});



    //submit button pressed
    handleFormSubmit=() =>{
        const clientId=this.props.match.params.clientId;

        this.setState({datarequested:true})
        let form_data = new FormData();
        form_data.append('client_name', this.state.clientname);
        form_data.append('company_email', this.state.companyemail);
        form_data.append('RegisteredPhoneNumber1', this.state.companyphone);
        form_data.append('box_number', this.state.boxnumber);
    
        this.state.companylogo==null?
        console.log("No profile file")
        :
        form_data.append('client_logo', this.state.companylogo,this.state.companylogo?this.state.companylogo.name:"");
        
        form_data.append('RegisteredPhoneNumber2', this.state.companyphonetwo);
        form_data.append('RegisteredPhoneNumber3', this.state.companyphonethree);
    
        form_data.append('apicode', this.state.apicode);
        form_data.append('activated', this.state.activated);
        form_data.append('update_url', this.state.update_url);
        form_data.append('client_type', this.state.client_type);

        form_data.append('collection_charge', this.state.collection_charge);
        form_data.append('collection_chargetype', this.state.collection_chargetype);
        form_data.append('payout_charge', this.state.payout_charge);
        form_data.append('payout_chargetype', this.state.payout_chargetype);

          axios.put(serverconfig.backendserverurl+`/api/mobilemoneyclients/${clientId}/`, form_data, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
        .then(res =>{
            this.setState({datarequested:false})
            message.info('Successfully updated client')
            this.componentmounttasks();
        }
        )
        .catch(error => console.log(error))
    }


    render(){
        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
          }else{
            return(
                <div>

                    <Row>
                    <Col xs="12" sm="6" lg="6">
                    <Card 
                            style={{padding:10}}
                            hoverable>
    
        <h4>Client Profile Update</h4>
        <Form
        onFinish={(event) => this.handleFormSubmit(event)}>

          <FormItem label="Client type"
             >
                <Select 
                placeholder="Client type" 
                //style={{ width: 120 }} 
                value={this.state.client_type} 
                onChange={(val)=>{this.setState({client_type:val})}}
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                >
                  <Option value={"banking"}>Banking</Option>
                  <Option value={"other"}>Other</Option>

                </Select>
            </FormItem>


            <FormItem label="Client Name"
              >
              <Input name="clientname"  placeholder="Put a name here." value={this.state.clientname} onChange={this.handleclientnameChange} />
            </FormItem>
  
            <FormItem label="Client Logo">
              <Input name="companylogo" type="file" accept="image/png, image/jpeg" placeholder="Company logo"  onChange={this.handleCompanyLogoChange}/>
            </FormItem>  
            
            <FormItem label="Client Email"

            >
              <Input name="companyemail" type="email"  placeholder="company@email.com" value={this.state.companyemail} onChange={this.handleCompanyEmailChange} />
            </FormItem> 
  
            <FormItem label="Phone Number">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphone} onChange={this.handleCompanyPhoneChange}/>
             </FormItem>
             <FormItem label="Phone Number Two">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphonetwo} onChange={this.handleCompanyPhonetwoChange}/>
             </FormItem> 
  
             <FormItem label="Phone Number Three">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphonethree} onChange={this.handleCompanyPhonethreeChange}/>
             </FormItem>  

  
            <FormItem label="Box number">
              <Input name="boxnumber"  placeholder="Box number here" value={this.state.boxnumber} onChange={this.handleCompanyBoxNumberChange} />
            </FormItem>
  
            <FormItem label="Transaction Update Url">
              <Input name="update_url"  placeholder="Update Url here" value={this.state.update_url} onChange={this.handleupdate_urlChange} />
            </FormItem>


            <FormItem label="Collection charge type"
             >
                <Select 
                placeholder="Charge type" 
                //style={{ width: 120 }} 
                value={this.state.collection_chargetype} 
                onChange={(val)=>{this.setState({collection_chargetype:val})}}
                showSearch
                optionFilterProp="children"
                >
                  <Option value={"byblockfigure"}>By block figure</Option>
                  <Option value={"bypercentage"}>By percentage</Option>

                </Select>
            </FormItem>


            <FormItem label="Collection charge"          
            >
              <Input maxLength={6} name="collection_charge" type='number'  placeholder="Retained Percentage  eg 3" value={this.state.collection_charge} onChange={this.handlecollection_chargeChange} />
            </FormItem>


            <FormItem label="Payout charge type"
             >
                <Select 
                placeholder="Payout type" 
                //style={{ width: 120 }} 
                value={this.state.payout_chargetype} 
                onChange={(val)=>{this.setState({payout_chargetype:val})}}
                showSearch
                optionFilterProp="children"
                >
                  <Option value={"byblockfigure"}>By block figure</Option>
                  <Option value={"bypercentage"}>By percentage</Option>

                </Select>
            </FormItem>


            <FormItem label="Payout charge"           
            >
              <Input maxLength={6} name="payout_charge" type='number'  placeholder="Value" value={this.state.payout_charge} onChange={(val)=>{this.setState({payout_charge:val.target.value})}} />
            </FormItem>


            <FormItem>
              <Button type="primary" htmlType="submit" >Update</Button>
            </FormItem>
            </Form>
                    </Card>
                       
    
                    </Col>

                    <Col xs="12" sm="6" lg="6">
                      <Card 
                            style={{padding:10,alignContent:'center',alignItems:'center'}}
                            hoverable
                            cover={
                            
                                <Image
                                width={100}
                                //height={'50%'}
                                src={this.state.profile.client_logo}    
                                />                   
                             }
                        >
                        </Card>


                   
                    </Col>
                
                    </Row>
    
                    <br/>
    
                </div>
            )

          }
     
    }
}

export default MobileClientDetail; 