import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Form,InputNumber,Modal,Popover,Spin,Popconfirm,message,Tooltip,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,DollarOutlined,RedoOutlined,FundViewOutlined,LoadingOutlined,MehFilled,PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../views/serverconn'
import MobileClientCreateForm from '../components/mobileclientcreateform'
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';

function callback(key) {
  console.log(key);
}

var token= ''


class MobileClientList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    clientcompanies: [],
    companymanagers:[],
    datarequested:true,
    walletmodalvisible:false,
    selectedsaccooject:{},
    date:moment().format(dateFormat).toString(),
    saccodebitamount:0,
    mobilephone:''
  };

      //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    //Now submit sale data to database
    axios.delete(serverconfig.backendserverurl+`/api/mobilemoneyclients/${id}`)
    .then(res =>window.location.reload(false),message.info('Successfully deleted client')
    )
    .catch(error => console.log(error)) 
  };


  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+'/api/mobilemoneyclients/')
    .then(res => {
        this.setState({
          clientcompanies:res.data
        })

        this.setState({datarequested:false})
    })

}

  /////////////////////////////////////
  // change company status
  changecompanystatus = (val,record) => {
    console.log(val)
    this.setState({companystatus:val})
    this.setState({datarequested:true})

    //now update the company openness
    let form_data = new FormData();
      form_data.append('activated', val);

      axios.put(`${serverconfig.backendserverurl}/api/mobilemoneyclients/${record.id}/`,form_data, {
          headers: {
          'content-type': 'multipart/form-data'
          }
      })
      .then(res => {
        this.setState({datarequested:false})
        window.location.reload(false)
      })
      .catch(error => console.log(error))

  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };



  handlemobilephoneChange= (e) => this.setState({ mobilephone: e});
  handlesaccodebitamountChange=(e)=>this.setState({saccodebitamount:e})



  render() {
    const columns = [
      {
        title: 'Client type',
        dataIndex: 'client_type',
        key: 'id',
        ...this.getColumnSearchProps('client_type'),
      },
      {
        title: 'Name',
        dataIndex: 'client_name',
        key: 'id',
        ...this.getColumnSearchProps('client_name'),
      },
      {
        title: 'API Code',
        dataIndex: 'apicode',
        key: 'id',
      },
      {
        title: 'Email',
        dataIndex: 'company_email',
        key: 'id',
      },
      {
        title: 'Account Balance',
        dataIndex: 'floatBalance',
        key: 'id',
        render:(text)=>
        <p><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}/></p>
      },      
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/mobileclients/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>View Client co Users</p>} title="View">
         <Link to={`/clientco_users/${record.id}`}><FundViewOutlined style={{color:'green'}}/></Link>
        </Popover>

        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        &nbsp;&nbsp;&nbsp;

        <Popover content={<p>View Client status</p>} title="Client Status">
        <Switch
              checked={record.activated}
              onChange={val=>{this.changecompanystatus(val,record)}}
              checkedChildren="Active"
              unCheckedChildren="     InActive"
            />
        </Popover>
        </p>,
      },

    ];


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="Mobile Money Clients" key="1">
            <Table 
            columns={columns}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.clientcompanies}
            scroll={{ x: 1000 }}
            bordered/>
            </Panel>
            </Collapse>
            <br />

 
            <Row > 
              <Col xs="12" sm="6" lg="6">
                <Collapse defaultActiveKey={['0']} onChange={callback}>
                <Panel header="Collapse Panel to open client creation Form" key="1">
                  <h2>Create Client here</h2>
                  <MobileClientCreateForm />
                </Panel>
                </Collapse>

              </Col>
   
          </Row>
          
        </div>
    )

    }

  
  }
}

export default MobileClientList; 

